// Here you can add other styles

input, textarea {
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

.c-sidebar .c-sidebar-nav-title,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: $black;
}

.btn-outline-primary {
    color: $black;
}
