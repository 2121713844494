a,
header,
.btn,
.table {
    color: rgba(0, 0, 21, 0.5) !important;
}

.c-dark-theme a,
.c-dark-theme header,
.c-dark-theme .btn,
.c-dark-theme .table {
    color: #fff !important;
}
